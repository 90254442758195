import React from "react"

import { useSiteMetadata } from "../hooks/useSiteMetaData"
import { PageLayout } from "../components/common/site/PageLayout"
import { PageMeta }   from "../components/common/site/PageMeta"

const NotFoundPage = () => (
  <PageLayout>
    <PageMeta title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </PageLayout>
)

export default NotFoundPage
